export const inIframe = () => {
  try {
    var inIframe = window.self !== window.top;
    if (inIframe) {
      //console.log("当前页面在iframe中");
      return true;
    } else {
      //console.log("当前页面不在iframe中");
      return false;
      //return true
    }
  } catch (e) {
    //console.error("无法确定当前页面是否在iframe中：", e);
    return false;
  }
};
